<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('undergraduate_transfer')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('undergraduate_transfer')"></HeaderMobile>
            </template>

            <info-collapse-area>
                <template v-slot:title>
                    {{ getObjectValue(student, 'student_number') }}, {{ getObjectValue(user, 'name') }} {{ getObjectValue(user, 'surname') }}
                </template>
                <template v-slot:detail>
                    <b-row>
                        <b-col cols="auto">
                            <b-form-group :label="$t('faculty')">
                                <div class="label-as-input">
                                    {{ student.faculty_name }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('program')">
                                <div class="label-as-input">
                                    {{ student.program_name }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('class')">
                                <div class="label-as-input">
                                    {{ student.class ? $t('class_x', {'class': student.class}) : '-' }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('number_of_semesters')">
                                <div class="label-as-input">
                                    {{ getObjectValue(student, 'semester_count') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('gno')">
                                <div class="label-as-input">
                                    {{ getObjectValue(student, 'cgpa') }}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </template>
            </info-collapse-area>

            <div>
                <p class="mb-2 font-weight-bold">{{ $t('undergraduate_transfer') }} - {{ $t('application_rules') }}</p>
                <div class="p-4 border rounded-sm">
                    Sociosqu graeci hac aliquip lobortis faucibus vituperatoribus salutatus. Eirmod sed mollis quam curae. Blandit lectus autem vidisse scripta esse sententiae.
                    Praesent dolorem rutrum inciderint purus semper. Mauris penatibus tortor inciderint neque. Conclusionemque dicat aliquip nihil fugit suscipit appetere sem
                    mattis donec. İnterdum dui cum omittam maximus pharetra tractatos suspendisse melius vituperatoribus.
                </div>
                <div class="d-flex justify-content-end mt-5">
                    <b-button variant="primary" @click="apply()">{{ $t('apply') }}</b-button>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    // Component
    import InfoCollapseArea from '@/components/elements/InfoCollapseArea'

    // Others
    import isset from 'isset-php'
    import Base from '@/plugins/Base'

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            InfoCollapseArea
        },
        metaInfo() {
            return {
                title: this.$t('undergraduate_transfer')
            }
        },
        data() {
            return {
                user: {},
                student: {},
                showExtraUser: false
            }
        },
        mounted() {
            this.user = this.$store.getters['auth/getUser'];

            // Öğrenci Değilse Yönlendir
            if (!isset(() => this.user.student.id)) {
                this.$router.push('/404');
            }
            else {
                this.student = this.user.active_student_program;
            }
        },
        methods: {
            apply() {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_apply'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            Base.LocalStorage.set('undergraduate_transfer_temp_data', {
                                corporate_type: 'in',
                                student_program_id: this.student.id,
                                type: 'domestic',
                                nationality_code: this.user.person.nationality_code,
                                nationality_name: this.user.person.nationality_name,
                                national_id: this.user.person.national_id,
                                name: this.user.name,
                                surname: this.user.surname,
                                birthdate: this.user.person.birthdate,
                                father_name: this.user.person.father_name
                            });
                            this.$router.push('/transfer/register');
                        }
                    });
            }
        }
    }
</script>

